export class AplicantModel {
  constructor() {
    this.informacion_interna = new InformacionInternaModel();
    this.solicitud_tarjeta = new SolicitudTarjetaModel();
    this.datos_tarjeta = new DatosTarjetaModel();
    this.datos_personales = new DatosPersonalesModel();
    this.datos_contacto = new DatosContactoModel();
    this.datos_laborales = new DatosLaboralesModel();
    this.datos_precheck = new DatosPrecheckModel();
    this.observaciones = new ObservacionesModel();
    this.entrega_documentacion = new EntregaDocumentacionModel();
    this.validacion = new ValidacionModel();
    this.observaciones_tracking = new Array<ObservacionesTackingModel>();
    this.adicionales = new Array<AplicanteAdicionalModel>();
    this.direcciones = new Array<DireccionModel>();
    this.telefonos = new Array<TelefonoModel>();
    this.detalles = new Detalles();
  }

  usuario: string;
  promotor: string;

  informacion_interna: InformacionInternaModel;
  solicitud_tarjeta: SolicitudTarjetaModel;
  datos_tarjeta: DatosTarjetaModel;
  datos_personales: DatosPersonalesModel;
  datos_contacto: DatosContactoModel;
  datos_laborales: DatosLaboralesModel;
  datos_precheck: DatosPrecheckModel;
  direcciones: Array<DireccionModel>;
  telefonos: Array<TelefonoModel>;
  observaciones: ObservacionesModel;
  entrega_documentacion: EntregaDocumentacionModel;
  validacion: ValidacionModel;
  observaciones_tracking: Array<ObservacionesTackingModel>;
  adicionales: Array<AplicanteAdicionalModel>;
  detalles: Detalles;
}

export class InformacionInternaModel {
  captura_inicio_fecha: string;
  captura_fin_fecha: string;
  decision_fecha: string;
  ultimo_modificador: string;
  estado: string;
}

export class SolicitudTarjetaModel {
  pcn: string;
  application_source: string;
  application_source_desc: string;
  nro_referencia: string;
}

export class DatosTarjetaModel {
  fecha_ingreso: string;
  fecha_aprobacion_LANAS: string;
  ref_NAPS: string;
  nro_envio: string;
}

export class DatosPersonalesModel {
  tipo_documento: string;
  estado_civil: string;
  numero_documento: string;
  nombre_conyuge: string;
  nombre_completo: string;
  nombre_primero: string;
  nombre_segundo: string;
  apellido_primero: string;
  nacionalidad: string;
  genero: string;
  fecha_nacimiento: string;
  residencia_anos: string;
  educacion: string;
  provincia: string;
  localidad: string;
  codigo_postal: string;
  calle: string;
  calle_nro: string;
  calle_piso: string;
  calle_dpto: string;
  direccion: string;
  vivienda: string;
  cantRellamados: number;
  cantRellamadosRec: number;
}

export class DatosContactoModel {
  telefono_fijo: string;
  email_personal: string;
  celular: string;
  email_laboral: string;
}

export class DatosLaboralesModel {
  profesion: string;
  cuil: string;
  tipo_trabajador: string;
  empresa: string;
  cargo: string;
  antiguedad: string;
}

export class DatosPrecheckModel {
  scoreVeraz: string;
  scoreNosis: string;
  incomeNosis: string;
  incomePercentil: string;
  vigenciaPrecheck: string;
  peorSituacionBCRA: string;
  peorSituacionBUREAU: string;
  antiguedadBCRA: string;
  ingresoReal: string;
  limiteFinal: string;
  limiteFinalOld: string;
}

export class DireccionModel {
  origen: string;
  direcciones: string;

  constructor(origen: string, direccion: string) {
    this.origen = origen;
    this.direcciones = direccion;
  }
}

export class TelefonoModel {
  origen: string;
  telefono: string;

  constructor(origen: string, telefono: string) {
    this.origen = origen;
    this.telefono = telefono;
  }
}

export class ObservacionesModel {
  observacion: string;

  constructor(observacion: string = null) {
    this.observacion = observacion;
  }
}

export class EntregaDocumentacionModel {
  entrega_dni: boolean;
  entrega_recibos_ingresos: boolean;
  entrega_resto_documentacion: boolean;
}

export class ObservacionesTackingModel {
  area: string;
  usuario: string;
  mensaje: string;
  fecha: string;
}

export class AplicanteAdicionalModel {
  crud: string;
  tipo_documento: string;
  numero_documento: string;
  nombre_completo: string;
  nombre_primero: string;
  nombre_segundo: string;
  apellido_primero: string;
  genero: string;
  fecha_nacimiento: string;
  vinculo: string;
  usuario: string;
}

export class Detalles {
  fecha_ultima_modif: string;
  fecha_captura: string;
  fecha_rellamado: string;
  hora_rellamado: string;
  producto: string;
  cantidad_suples: string;
  canal: string;
  promotor: string;
  promotor_original: string;
  fecha_prox_contacto: string;
  hora_prox_contacto: string;
  fecha_pactacion: string;
  hora_pactacion: string;
  ciclo: string;
  delivery_code: string;
}

export class ValidacionModel {
  accion: string;
  motivo: string;
}

export class BusquedaFiltrosModel {
  fecha: string;
  producto: string;
  dni: string;
  cuil: string;
  pcn: string;
  nombre: string;
  aprobado_lanas: string;
  refnaps: string;
  promotor: string;
  estado: string;
  motivo: string;
  fechaDesde: string;
  fechaHasta: string;
 tipoVenta: string; 
}

export class CustomerModel {
  fechaAprobado: string;
  fechaArchivo: string;
  fechaCapturaPrecheck: string;
  fechaCarga: string;
  fechaModificacion: string;
  fechaEntrega:string;
  //producto: string;
  docNum: string;
  deliveryCd : string;
  cuil: string;
  pcn: string;
  denegado: boolean;
  apellido: string;
  nombreCompleto: string;
  nombre1: string;
  nombre2: string;
  nacionalidad: string;
  fechaAprobacionLanas: string;
  refNaps: string;
  promotor: string;
  estado: string;
  intPersonaSuples: IntPersonaSuples[];
  internalSourceCode: InternalSourceCode;
  //internalDataCustomer: InternalDataCustomer;
  intObservaciones: IntObservaciones[];
  intSocioData: IntSocioData;
  tipoDoc:string;
  vigenciaPrecheck:number;
  ciclo:string;
  deliveryCode:string;
  observacion:string;
  accion:string;
  motivo:string;
  canalArchivo:string;
  tipoVenta: string;
  tipoVentaName: string;
  equifaxInt: equifaxInt;
  nosisInt: nosisInt;
  informedProduct : string;
  informedPromotor : string;
  productoObj: ProductoObj;
  generoString: string;
  professionString: string;
  estadoRegistro: string;
  area: string;
  keyDigits: string;
  internalRiesgoResponse: internalRiesgoResponse;
}
export class ProductoObj{
  key : string;
  value : string;
  data: dataProduct
}

export class dataProduct{
  id : string;
  income : string;
  prod : string;
  prodLgf : string;
  prodMgm : string;
  prodName : string;
}

export class InternalSourceCode{
  idPromotor: string;
  comercializadora: string;
  nombreProducto: string;
  prod: string;
  sourceCode: string;
  sourceCodeCompleto: string;
  canal: string;
  ciclo: string;
  delivery1: string;
  delivery2: string;
  descripcion:string; 
  idOriginalProd: string;
  originalProdName: string;
}

export class IntPersonaSuples {
  crud: string;
  genero: string;
  vinculo: string;
  nombre1: string;
  nombre2: string;
  apellido: string;
  activo: boolean;
  bridgerStatus: any;
  caseNumber: string;
  caseStatus: string;
  docNumSuple: string;
  fnac: string;
  insertDate: number;
  lastUpdate: number;
  nombreCompleto: string;
  pcnSuple: string;
  plasticColorType: string;
  tipoDoc: string;
  usuarioIc: string;
  index: number;
  denegado: boolean;
  denegadoString: string;
  idProd: number;
  productName: string;
  area: string;
  accion: string;
  motivo: string;

  nosisCuil: string;
  nosisNse: string;
  nosisNsePercentil: string;
  nosisPorcentajeCoincidencia: string;
  nosisGenero:string;
  nosisFnac:string;
  nombreTj: string;
  arrVin: any = [];
  vinculoSelect: string;
  disabledSelect: boolean;
  ageVinculo: string;
  disableSupleGender: boolean;
  disableSupleFnac:boolean;
}

export class InternalDataCustomer{
  direccion: string;
  localidad: string;
  telefono: string;
  celular: string;
}

export class FilteredApplicant {

    code:string;
    data:[];
}


export class IntSocioData{
  birthDate: string;
  area: string;
  birthdate: string;
  cardName: string;
  city: string;
  civilState: string;
  company: string;
  cuil: string;
  dni: string;
  dniType: string;
  email: string;
  firstName: string;
  gender: string;
  income: string;
  ivaType: string;
  lastName: string;
  loc: string;
  memberSinceTitular: string;
  monthlySalary: string;
  mr: string;
  nationality: string;
  ocupation: string;
  postalCode: string;
  profession: string;
  province: string;
  provinceName: string;
  secondName: string;
  street: string;
  tel1: string;
  tel2: string;
  keyDigits: string;
}

export class IntObservaciones{
  area: string;
  fecha: string;
  id: string;
  observacion: string;
  persona:string;
}


export class equifaxInt{
  antiguedadBcra: string;
  antiguedadBureau: string;
  antiguedadCc: string;
  antiguedadTc: string;
  bcraPeorSitU12m: string;
  limiteFinal: string;
  peorSitBureau12: string;
  percCompromisosmensuales: string;
  scoreVeraz: string;
  axpRiskScore: string;
  limiteTarjetaPreferida: string;
  incomeAvg: string;
  }

export class nosisInt{
  actividadCodigo: string;
  actividadDescripcion: string;
  actividadEmpleadorCodigo: string;
  actividadEmpleadorSector: string;
  nseIngresoprom: string;
  percCompromisosmensuales: string;
  scoreNosis: string;
}

export class internalRiesgoResponse {
  cuil: string;
  custXrefId: string;
  cmId1: string;
  genderCd: string;
  idType: string;
  centurionOk: number;
  upgrade: string;
  reverse: string;
  companion: string;
  riskVarC: string;
  riskVarT: string;
  riskVarPd: string;
  riskVarTpd: string;
  riskVarL: string;
  riskVar6: string;
  riskVar7: string;
  riskVar8: string;
  crRskGenlScoreCd: string;
}
