import { Component, OnInit } from '@angular/core';

import { LoadingComponent } from '../../component/shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';

import { InternalDocRecoveryService } from '../../services_internal/internalDocrecovery/internalDocrecovery.service';
import {  BusquedaFiltrosModel, FilteredApplicant, CustomerModel,InternalSourceCode         
        } from 'src/app/models/aplicante.model';

import {  GenerosList, TiposDocumentosList, VinculosList,
          NacionalidadesList, EstadosCivilList, ProvinciasList,
          TiposViviendasList, EducacionNivleslList, ProfesionesList,
          TrabajosTipolList, ProductosList, CargoslList,
          AccionAplicanteList, MotivosAplicanteList, HorasList, SourceCodesList} 
          from 'src/app/models/lists.model';

import { CRUD, Environment } from 'src/app/config/environment';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import * as moment from 'moment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BaseComponent } from '../../component/shared/base.component';
import { Roles } from 'src/app/auth/models/roles.static';
import { IntPersonaSuples } from 'src/app/models/aplicante.model';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';

@Component({
  selector: 'app-internalDocrecovery',
  templateUrl: './internalDocrecovery.component.html',
  styleUrls: ['./internalDocrecovery.component.css']  
})

export class InternalDocRecoveryComponent  extends BaseComponent  implements OnInit {

  
  public es: any;
  public title: string = "RECUPERO DE INFORMACION";
  public showResult : boolean;
  public tablereports = null;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean; 



  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;


  public docrecoveryData:any = [];
  public aplicanteCurrent: CustomerModel = new CustomerModel;
  public aplicanteCurrentBk: CustomerModel = new CustomerModel;

  public adicionalCurrent: IntPersonaSuples = new IntPersonaSuples;
  
  
  public adicionalCurrentBk: IntPersonaSuples = new IntPersonaSuples;

  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validationsAdicional: Array<any> = new Array<any>();
  public validationsAdicionalShow: boolean; 
  public fechaRellamadoDisabled: boolean = true;

  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public selectedApplicant : FilteredApplicant = new FilteredApplicant;

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  public accionSupleList: AccionAplicanteList = new AccionAplicanteList();
  public accionListStore: AccionAplicanteList = new AccionAplicanteList();

  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public cargosList: CargoslList = new CargoslList();
  public horasList: HorasList = new HorasList();
  public sourcecodesList: SourceCodesList = new SourceCodesList();
  public sourcecodesListForApplicant: Array<any> = new Array<any>();
  public productsApplicationSource: any = {data:[]};
  public rolConsulta : boolean=true;
  public accionVinculo: boolean = true;
  fecha_rellamado_mindate: string;

  public data : any;
  public showTitleSuples: boolean = false;
  public showAplicanteDetallesIso : boolean = false;
  public vinculo : boolean = true;

  public ngSelect: string = "";
  public ngSelectAccion: string = "";
  public ngVinculo: string = "";
  
  public resumeString: string = "";

  public infoRecoveryList:any = [];
  public provinceData:any = [];
  public vinculosData:any = [];
  public vinculos:any = [];
  public initSelectAdd: string = "";
  public minDate: Date;
  public maxDate: Date;
  public tipoDeVentaList : Array<any> = new Array<any>();
  public sourceCodes : InternalSourceCode = new InternalSourceCode();
  public changeSourceCode : boolean = true;



  constructor(private commonDataService: CommonDataService,
              private internalDocrecoveryService: InternalDocRecoveryService, 
              private sessionStorageService: SessionStorageService,
              private excelExportService: ExcelExportService) {
                super();
                this.sessionStorageService.rolValidate("internalDocRecovery");
              }

  ngOnInit() {

    const today = new Date();
    this.minDate = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate());
    this.maxDate = new Date(today.getFullYear() - 14, today.getMonth(), today.getDate());
    
    this.fecha_rellamado_mindate = new Date().getFullYear().toString() + ":2050";
    this.busquedafiltros.producto = "";    
     
    this.listProvinceLoad();
    this.setCalendarLocale();
    this.validationAccionLoad();   
    this.profesionesLoad(); 
    this.loadProductos();
    this.loadSourcecodes();
    this.listVinculosLoad();
    this.rolesAutorization();
    this.fillTipodeVenta();
   
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  loadReportResults(){

    this.loading = true;
    this.loadReportRequest();
    return true;
  }

  loadReportRequest(){
    this.showResult = false;
    let request = { 
        "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
        "dni": Utils.parseEmptyNull(this.busquedafiltros.dni), 
        "fecha": Utils.isNullOrEmpty(Utils.getTimesTamp(this.busquedafiltros.fecha)) ? null : Utils.getTimesTamp(this.busquedafiltros.fecha),
        "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre), 
        "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn), 
        "producto": Utils.parseEmptyNull(this.busquedafiltros.producto),
        "cuil": Utils.isNullOrEmpty(this.busquedafiltros.cuil) ? null : this.busquedafiltros.cuil,
        "tipoVenta":Utils.parseEmptyNull(this.busquedafiltros.tipoVenta) 
    };         

    this.internalDocrecoveryService.getApplicantsReportByArea(request).subscribe(
      response => {
        if (response.status == 200 && response.body != null) {
          this.docrecoveryData = response.body;
                    
          this.docrecoveryData.forEach(i=>{
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.informedProduct ) );

            if( i.tipoVenta == "SUP" && i.intSocioData != null && i.intSocioData.producto != null ){
              prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.intSocioData.producto ) );
            }
            i.productoObj = prod;
            i.accion = ( i.accion != null ) ? i.accion : "";
            i.motivo = ( i.motivo != null ) ? i.motivo : "";

            if( i.intPersonaSuples.length ){

              for( var j = 0; j < i.intPersonaSuples.length; j++ ){
  
                i.intPersonaSuples = i.intPersonaSuples.filter(function (sup) {
                  return sup.denegado !== true;
                })
              }
            }
          })  
         
          
          this.setupTable();             

        }
        else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true, "INFO");
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
        throw error;
      }
    );

    return true;
  }

  showAplicante(pcn:string){
    this.loading = true;

    let request = {
      "pcn":pcn
    };
    
   
    
   
    this.commonDataService.pingHoldInternal(request).subscribe(
      response => {
  
        if( response.status == 200 ){

          this.setDataToShow( pcn );
          
    

        }else if( response.status == 226 && response.body != null){

          this.alertMessage = new AlertModel( "Este socio está siendo editado por "+response.body["message"],this.title, true,"WARNING");
          this.loading = false;
        }else{

          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          this.loading = false;
        }
        
      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );
  }

  public setDataToShow( pcn:string ){
    this.ngSelect = "";
    this.ngSelectAccion = "";
    this.changeSourceCode=true;
    
    let request = {
        "pcn": pcn
    };
  
    
    this.selectedApplicant.data = this.docrecoveryData.filter(data => data.pcn == request.pcn)
    if(this.selectedApplicant.data.length == 0){
      this.selectedApplicant.code = "100"
    }
  
    this.data = this.selectedApplicant
    if (this.data && this.data['code'] == "100"){
      this.alertMessage = new AlertModel("No se pudieron obtener los datos." , this.title, true);
      this.loading = false;
      return;
    }
    if (this.data && this.data["data"]) {

      this.aplicanteCurrent = Object.assign(this.aplicanteCurrent, this.data["data"][0]);
      this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
      this.aplicanteCurrent.generoString = this.generosList.data.find(i=>i.key == this.aplicanteCurrent.intSocioData.gender)?.value;
     
      this.sourcecodesForApplicant();
      this.validationsShow = false;

    if( this.data.data[0].tipoVenta == "SUP" ){
      this.showAplicanteDetallesIso = true;
      
    }else{
      this.showAplicanteDetallesIso = false;
    }
  
        //PROVINCIA POR NUMERO
    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.province ) ){
      let nameProvince = this.provinceData.filter(data => data.codigo == this.aplicanteCurrent.intSocioData.province);
      if( !Utils.isNullOrEmpty (nameProvince)){
        this.aplicanteCurrent.intSocioData.provinceName = nameProvince[0].descripcion
      }
  
    }
  
        //PROFESION POR NUMERO
    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.profession ) ){
      let professionString = this.profesionesList.data.filter(data => data.key == this.aplicanteCurrent.intSocioData.profession);
      if( !Utils.isNullOrEmpty (professionString)){
        this.aplicanteCurrent.professionString = professionString[0].value
      }

    }
  
    let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
    this.aplicanteCurrent.tipoVenta = ( typeof arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] : this.aplicanteCurrent.tipoVenta;
      ////////////////////////////////////////////////////////////////////////////////////////
    if( this.aplicanteCurrent.intPersonaSuples.length ){
      this.showTitleSuples = true;
      
      for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){
        let obSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
        let prodSuple = null;
        obSuple.index = i+1;


        if( obSuple.nombreCompleto == "" || obSuple.nombreCompleto == null ){
          obSuple.nombreCompleto = obSuple.nombre1 + " " +obSuple.apellido;
        }

        if( obSuple.tipoDoc == "" || obSuple.tipoDoc == null ){
          obSuple.tipoDoc = "DNI";
        }
        if( obSuple.denegado != null && obSuple.denegado ){
          obSuple.denegadoString = "Denegado";
        }else{
          obSuple.denegadoString = "Aprobado";
        }
        if( !Utils.isNullOrEmpty( obSuple.idProd ) ){
          prodSuple = this.productosList.data.filter(data => data.key == obSuple.idProd);
          obSuple.productName = prodSuple[0].value;
        }else{
          obSuple.productName = this.aplicanteCurrent.productoObj.value;
        }

        let compareTwoDates = Utils.compareTwoDates(this.aplicanteCurrent.intSocioData.birthDate , obSuple.fnac)

        if( compareTwoDates == 1){
          obSuple.ageVinculo = "ME"
        }else if( compareTwoDates == -1){
          obSuple.ageVinculo = "MA"
        }
        
        const accion = obSuple.accion.toUpperCase()
        obSuple.arrVin = this.getVinculo(obSuple.genero , obSuple.ageVinculo)
        let select = obSuple.arrVin.find(v => v.nombreVinculo === obSuple.vinculo);
        let checkError  = "";
        if(accion == "DATO ERRONEO"){
          //(logica para VINCULO,GENERO,FNAC)
          console.log(obSuple.motivo)
          if(obSuple.motivo.includes("Vinculo")){
            obSuple.disabledSelect = true
            obSuple.vinculoSelect = "";
            checkError = "vinculo"
          }
          if(obSuple.motivo.includes("Género") || obSuple.motivo.includes("Genero")){
            
            obSuple.disableSupleGender = true;
            obSuple.vinculoSelect = obSuple.vinculo
            obSuple.disabledSelect = true
            obSuple.vinculoSelect = "";
            obSuple.genero = this.aplicanteCurrent.intPersonaSuples[ i ].nosisGenero;
            checkError = "genero"
            if(checkError.includes("vinculo")){
              checkError = "vinculo,genero"
            }
          }
          if(obSuple.motivo.includes("fecha de nacimiento") || obSuple.motivo.includes("Fnac")){
            obSuple.disabledSelect = true
            obSuple.vinculoSelect = "";
            obSuple.disableSupleFnac = true;
            obSuple.vinculoSelect = obSuple.vinculo
            obSuple.fnac = this.aplicanteCurrent.intPersonaSuples[ i ].nosisFnac;
  
          }
          if(checkError == "vinculo,genero" || checkError.includes("genero")){
            if(this.aplicanteCurrent.intPersonaSuples[ i ].nosisGenero == "M"){
              obSuple.arrVin = this.getVinculo("M", obSuple.ageVinculo)
              select = obSuple.arrVin.find(v => v.nombreVinculo === obSuple.vinculo);
            }else{
              obSuple.arrVin = this.getVinculo("F", obSuple.ageVinculo)
              select = obSuple.arrVin.find(v => v.nombreVinculo === obSuple.vinculo);
            }
          }
        }

          if(select){
            obSuple.vinculoSelect = obSuple.vinculo
          }else{
            obSuple.disabledSelect = true
            obSuple.vinculoSelect = "";
          

        }//if dato erroneo
  
      }
    }else{
      this.showTitleSuples = false;
    }
      this.aplicanteCurrent.observacion = "";
      if( this.aplicanteCurrent.intObservaciones.length ){
        this.aplicanteCurrent.intObservaciones.sort(function(a, b) {
          var textA = a.id;
          var textB = b.id;
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        });
      }
  
      ////
      if(this.aplicanteCurrent.internalSourceCode){//CONDICION PARA VENTAS ISOLADAS (CUANDO NO TIENEN SOURCECODES)
        var value = this.aplicanteCurrent.internalSourceCode.idOriginalProd;
      }else{
        var value = this.aplicanteCurrent.informedProduct;
      }
      
      let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
      if( prod ){
        if(this.aplicanteCurrent.internalSourceCode){//CONDICION PARA VENTAS ISOLADAS (CUANDO NO TIENEN SOURCECODES)
          this.aplicanteCurrent.internalSourceCode.originalProdName = prod.value; 
        }else{
          this.aplicanteCurrent.informedProduct = prod.value; 
        }
      
      }
      if(this.aplicanteCurrent.internalSourceCode.sourceCodeCompleto == "" || this.aplicanteCurrent.internalSourceCode.sourceCodeCompleto == null){
        this.changeSourceCode=false;
      }
  
      if( this.data.data[0].tipoVenta == "SUP" ){
        this.showAplicanteDetallesIso = true;
        if(!this.aplicanteCurrent.intPersonaSuples.length){
  
          this.accionAplicanteList.data  = Utils.setAccionListSuples(this.accionAplicanteList.data);
  
        }
        
      }else{
        this.showAplicanteDetallesIso = false;
        this.accionAplicanteList.data = this.accionListStore.data;
      }
  
  
      this.sourcecodesListForApplicant = [];
      if( !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery1 ) && !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery2 ) ){
        this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery1 );
        this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery2 );
        this.aplicanteCurrent.deliveryCode = this.aplicanteCurrent.internalSourceCode.delivery1;
      }
      
        this.showAplicanteDetalles = true;
        this.showResult = false
        this.loading = false;
        this.refactorCp();
        this.setupTootip();
        window.scroll(0, 0);
        // this.loadDataRolConsulta(pcn);
        this.disableInputRolConsulta()
        this.getSourceCodeRecovery(pcn);
        this.commonDataService.pingHoldInternalTimerStart(pcn);
      }
  }
  
  getVinculo(genero: string , ageVinculo: string): any[] {
    const vinculos = this.vinculosData
      //.filter(item =>(item.genero === genero || item.genero === "G"))
      //.filter(item => item.valEdad !== ageVinculo)
      .sort((a, b) => a.nombreVinculo.localeCompare(b.nombreVinculo));
  
    return vinculos;
  }

  public loadDataRolConsulta(pcn:string){
    // let request = {
    //   "pcn" : pcn
    // }
    // this.internalDocrecoveryService.getApplicantDataByArea(request).subscribe(
    //   data => {
    //     this.productsApplicationSource.data = data["data"]
    //     if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent) == null)
    //     this.disableInputRolConsulta()
    //   },
        
    //   error => console.error(error));   
  }

  public disableInputRolConsulta(){
  
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")     
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")
      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles

  if (rolesSession.includes("AMX_INT_CONSULTA")){
    this.rolConsulta=false
  }
}

  listAplicanteDireccinesTelefonos(origen:string){

    // let data = Array<any>();
    // let direciones = this.aplicanteCurrent.direcciones.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    // let telefonos = this.aplicanteCurrent.telefonos.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    // let length = (direciones.length >= telefonos.length) ? direciones.length : telefonos.length;

    // for(let i = 0; i < length; i++){
    //   data.push({
    //     "direccion" : direciones[i] != null ? direciones[i].direcciones : "",
    //     "telefono" : telefonos[i] != null ? telefonos[i].telefono : "",
    //   });   
    // }
   
    // return data;
  }

  confirmSave(){

    try{
      if(!this.rolConsulta){
        this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
        return;
      }
      if( Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.sourceCodeCompleto )){
        this.alertMessage = new AlertModel("Seleccione un SourceCode",this.title, true, "WARNING");
        return;
      }
      if( !Utils.isNullOrEmpty( this.ngSelectAccion ) && Utils.isNullOrEmpty( this.ngSelect ) ){
        this.alertMessage = new AlertModel("Seleccione un motivo",this.title, true, "WARNING");
        return;
      }

      for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){
        var objSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
        let selectVinculo = $( "#select-vinculo-"+objSuple.index ).val()
        if( Utils.isNullOrEmpty( selectVinculo )){
          this.alertMessage = new AlertModel("Seleccione un vinculo", this.title, true, "WARNING");
          return;
        }
        
        if(Utils.isNullOrEmpty(objSuple.fnac)){
          this.alertMessage = new AlertModel("Seleccione fecha de nacimiento del adicional", this.title, true, "WARNING");
          return;
        }

        let birthDate = new Date(objSuple.fnac.split('/').reverse().join('-'));
        let today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        let monthDiff = today.getMonth() - birthDate.getMonth();
      
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
      
        if (age < 14 ) {
          this.alertMessage = new AlertModel("La edad del adicional no puede ser menor de 14 años.", this.title, true, "WARNING");
          return;
        }else if ( age > 120 ){
          this.alertMessage = new AlertModel("La edad del adicional no puede ser mayor de 120 años.", this.title, true, "WARNING");
          return;
        }else{
          objSuple.nosisFnac = objSuple.fnac
        }
      }
      
    }catch{}
    
    this.alertConfirmEvent = this.onConfirmEvent;
    let aplicante: any = new Object;
    aplicante = this.getCustomerToSend( false );
    
    this.alertMessage = new AlertModel("<b>Confirma que desea registrar la información?</b>"+this.resumeString,this.title,true, "CONFIRM");	
  }
  	
  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteData();
    }
  }

  getCustomerToSend(final:boolean):any{
    let aplicante: any = new Object;
    let cantSupAproved = 0;
    let cantSupDenied = 0;
    let titAproved: string = "Sin acción seleccionada";

    this.aplicanteCurrent.motivo = this.ngSelect;
    this.aplicanteCurrent.accion = ( !Utils.isNullOrEmpty( this.ngSelectAccion ) ) ? this.ngSelectAccion : null;

    for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){
      var objSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
      objSuple.accion = this.aplicanteCurrent.accion
      objSuple.motivo = this.aplicanteCurrent.motivo
      if( !objSuple.denegado ){
        cantSupAproved++;
      }
    }
    aplicante = this.aplicanteCurrent;
    aplicante.persona = this.sessionStorageService.getCurrentUser()?.username;
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;

    if( final ){
      if( !Utils.isNullOrEmpty( this.aplicanteCurrent.fechaEntrega ) ){
        aplicante.fechaEntrega = Utils.getTimesTamp( this.aplicanteCurrent.fechaEntrega );
      }else{
        aplicante.fechaEntrega = null;
      }
    }

    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.observacion ) ){
      aplicante.observaciones = this.aplicanteCurrent.observacion;
    }else{
      aplicante.observaciones = null;
    }

    
    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.accion ) && this.aplicanteCurrent.accion.indexOf( "APROB" ) != -1 ){
      titAproved = "APROBADO";
    }else if( !Utils.isNullOrEmpty( this.aplicanteCurrent.accion ) ){
      titAproved = this.aplicanteCurrent.accion;
    }
    this.resumeString = "<br>Titular: "+titAproved ;
    if( this.aplicanteCurrent.intPersonaSuples.length ){
      this.resumeString = this.resumeString +"<br>Supples aprobadas: "+ cantSupAproved;
    }
    return aplicante;
  }

  saveAplicanteData(){

    let aplicante: any = new Object;
    aplicante = this.getCustomerToSend( true );
    //console.log("aplicante", aplicante)
    this.saveAplicante(aplicante);
  }

  public saveAplicante(aplicante) {

    this.loading = true;
    this.internalDocrecoveryService.editIntPersona( aplicante ).subscribe(
      response => {

        if( response.status == 200 ){
         // this.alertConfirmEvent = this.onConfirmEventLoad; (AMEX-68)
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
         // this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "CONFIRM");(AMEX-68)
          this.showAplicanteDetalles = false;
          this.showResult = true;
          this.loading = true;//mando true porque se vuelve a hacer la busqueda
          this.loadReportResults();
          window.scroll(0, 0);
          
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
        
      }, err => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        this.loading = false;
        throw err;
        
      }
    
    );
    
    return true;
  
  }

  onConfirmEventLoad(confirm:any){

    if(confirm.confirm){ 
      this.loadReportResults();
      window.scroll(0, 0);
    }else{
      this.loadReportResults();
      window.scroll(0, 0);
    }
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }

 

  adicionalesList(){

    let adicionales = this.aplicanteCurrent.intPersonaSuples.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

    return adicionales;
  }

  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.intPersonaSuples.find((a)=>{

      return a.docNumSuple == dni;
    });

    let adicionalBk = this.aplicanteCurrentBk.intPersonaSuples.find((a)=>{
      return a.docNumSuple == dni;
    });

    this.adicionalCurrent  = Object.assign({}, adicional);
    this.adicionalCurrentBk = adicional;
    this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

    this.showAdicionalesEditar = true;
    
    return true;
  }

  adicionalesNuevo() {

    let adicionales = this.aplicanteCurrent.intPersonaSuples.filter(a=>a.crud != CRUD.DELETE);
    if(adicionales.length >= 5){
      this.alertMessage = new AlertModel("Solo se permiten hasta 5 adicionales.", this.title,true, ALERT_TYPE.INFO);	
      return false;
    }

     this.adicionalCurrent  = new  IntPersonaSuples();
     this.adicionalCurrentBk  = null;
     this.adicionalCurrent.crud = CRUD.CREATE;     
     this.showAdicionalesEditar = true;

     return true;
 }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
    this.validationsAdicionalShow = false;
    this.validationsAdicional = [];
  }

  adicionalesGuardar(){

    if(!this.validationsAdcionalForm()){
      this.validationsAdicionalShow = true;
      return false;
    }
    
    this.adicionalCurrent.docNumSuple = this.adicionalCurrent.docNumSuple.replace(/[^0-9]+/,"");

    let adicional = this.aplicanteCurrent.intPersonaSuples.find((a)=>{
      return a.docNumSuple == this.adicionalCurrent.docNumSuple &&
             a.crud != CRUD.DELETE;;
    });
    
    let dniChangeCurrent = adicional && !this.adicionalCurrentBk ? adicional.docNumSuple == this.adicionalCurrent.docNumSuple: false;
    let dniChangeOriginal = adicional && this.adicionalCurrentBk ? this.adicionalCurrentBk.docNumSuple != this.adicionalCurrent.docNumSuple: false;
    
    if(dniChangeCurrent  || dniChangeOriginal){
      this.alertMessage = new AlertModel("Ya existe un adicional con el mismo número documento.", this.title,true, ALERT_TYPE.INFO);	
      return false;      
    }

    
    if(adicional || this.adicionalCurrentBk) {
        let index = this.aplicanteCurrent.intPersonaSuples.indexOf(adicional ?? this.adicionalCurrentBk);

        this.aplicanteCurrent.intPersonaSuples[index] = Object.assign({},  this.adicionalCurrent );
        this.aplicanteCurrent.intPersonaSuples[index].usuarioIc = this.sessionStorageService.getCurrentUser().username;
        
    }else{
      this.adicionalCurrent.usuarioIc = this.sessionStorageService.getCurrentUser().username;
      this.aplicanteCurrent.intPersonaSuples.push(this.adicionalCurrent);
      
    }

    this.adicionalCurrentBk = null;
    this.showAdicionalesEditar = false;
    return true;

  }

  adicionalesBorrarConfirm(confirm:any){

    if(confirm.confirm){
      let adicional = this.aplicanteCurrent.intPersonaSuples.find((a)=>{

        return a.docNumSuple == confirm.data;
      });
  
     let index =  this.aplicanteCurrent.intPersonaSuples.indexOf(adicional);

     this.aplicanteCurrent.intPersonaSuples [index].crud = CRUD.DELETE;;
    }
  }

  
  adicionalesBorrar(dni:any){

    this.alertConfirmEvent = this.adicionalesBorrarConfirm;
    this.alertMessage = new AlertModel("Esta acción eliminará el siguiente registro: " + dni + "\nEsta seguro de continuar?",this.title,true, "CONFIRM", dni);	
        
  }

  validationAccionLoad(){

    this.internalDocrecoveryService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
          this.accionSupleList.data = Utils.setAccionListSuples( data['data'] );
          this.accionListStore.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;

  }

  
  profesionesLoad(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        }        
        else 
          console.error(data);  
          
        
      },
      error => { 
        console.error(error);
      }
    );

    return true;

  }

  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {      
          
          this.productosList.data = Object.assign([], data['data']);

        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    );
    }

  loadSourcecodes(){

    this.commonDataService.getIntSourcecodes().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.sourcecodesList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    ); 

  }

  sourcecodesForApplicant(){

      this.sourcecodesListForApplicant = [];
      let  source =  this.sourcecodesList.data.find(s=>s.source_code_completo == this.aplicanteCurrent.internalSourceCode.prod);
      

      if(source != null){
        this.sourcecodesListForApplicant.push({key:source.delivery_1, value: `Delivery Code 1 [${source.delivery_1}]`});
        this.sourcecodesListForApplicant.push({key:source.delivery_2, value: `Delivery Code 2 [${source.delivery_2}]`});

      }

  }

  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.internalDocrecoveryService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];
          this.motivosAplicanteList.data.sort((a, b)=> a.key.localeCompare(b.key));  
            
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        console.error(error + "😬");
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }

  aplicanteMotivoChange(item){
   
    if(this.aplicanteCurrent.accion == "PRECHECK COMPLETO" ){
        this.fechaRellamadoDisabled = false;
    }else{
      this.fechaRellamadoDisabled = true;
      
    }
  
  }

  aplicanteVinculoChange(item, index) {
    this.aplicanteCurrent.intPersonaSuples[index - 1].vinculo = item;
  }

  aplicanteGenderChange(item, index) {
    this.aplicanteCurrent.intPersonaSuples[index - 1].genero = item;
  }

  supleAccionChange(item, index){

    this.loadingMotivo = true;
    let request = {"parent" : item}
    let motivosArray = [];

    this.aplicanteCurrent.intPersonaSuples[ index - 1 ].accion = item;
    this.internalDocrecoveryService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          
          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  
          
          motivosArray = data['data'];
          const $select = document.querySelector("#select-motivo-"+index);
          var options = document.querySelectorAll("#select-motivo-"+index+" option");
          options.forEach(o => o.remove());
          const optionInit = document.createElement('option');
          optionInit.text = "Seleccionar";
          $select.appendChild(optionInit);
          for( var i = 0; i < motivosArray.length; i++ ){
            const option = document.createElement('option');
            var obj = motivosArray[ i ];
            option.value = obj.value;
            option.text = obj.key;
            $select.appendChild(option);
          }

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }
    
  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
    }

  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 2,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
          {
            targets: 3,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ],        
        language: Environment.DataTableLanguageSetting
        
       });


    });

  }  

  setCalendarLocale(){

    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mir", "Juv", "Vir", "Sab"],
      dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
      monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
      monthNamesShort: [ "Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
  };
  }
  

  public validationsForm(){
    this.validations = new Array<any>();

    
    if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.accion) == null){
      this.aplicanteCurrent.accion = "";
      this.validations.push({"validacion.accion" : "este campos es requerido."})
    }  

    if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.motivo) == null){
      this.aplicanteCurrent.motivo = "";
      this.validations.push({"validacion.motivo" : "este campos es requerido."})
    } 

       
    if(this.isNullOrEmpty(this.aplicanteCurrent.intSocioData.firstName)){
      this.validations.push({"datos_personales.nombre_primero" : "este campos es requerido."});
      }

      if(this.isNullOrEmpty(this.aplicanteCurrent.intSocioData.lastName)){
            this.validations.push({"datos_personales.apellido_primero" : "este campos es requerido."});
      }	 

    if(this.isNullOrEmpty(this.aplicanteCurrent.intSocioData.cardName))
       this.validations.push({"datos_personales.nombreCompleto" : "este campos es requerido."})

    if(this.nacionalidadesList.data.find(v=>v.value==this.aplicanteCurrent.intSocioData.nationality) == null){
        this.aplicanteCurrent.nacionalidad = "";
        this.validations.push({"datos_personales.nacionalidad" : "este campos es requerido."})
    }  

    if(this.isNullOrEmpty(this.aplicanteCurrent.intSocioData.birthdate))
       this.validations.push({"fnac" : "este campos es requerido."})

    if(!this.isNullOrEmpty(this.aplicanteCurrent.intSocioData.birthdate)){
      
        let b = moment(this.aplicanteCurrent.intSocioData.birthdate, "DD/MM/YYYY");
        
        if(moment().diff(b, 'years') < 18)       
          this.validations.push({"fnac" : "no se permite menor a 18 años"})
    }

     return this.validations.length == 0;
  }

  public validationsMessage(name):string{
    if(!this.validationsShow){
      return null;
    }
      
    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  public validationsAdcionalForm(){
    this.validationsAdicional = new Array<any>();


    if(this.tiposDocumentosList.data.find(v=>v.key==this.adicionalCurrent.tipoDoc) == null){
      this.adicionalCurrent.tipoDoc = "";
      this.validationsAdicional.push({"adicionalCurrent.tipoDoc" : "este campos es requerido."})
    }  

    if(this.isNullOrEmpty(this.adicionalCurrent.docNumSuple))
             this.validationsAdicional.push({"adicionalCurrent.docNumSuple" : "este campos es requerido."});

    
    if(this.isNullOrEmpty(this.adicionalCurrent.fnac))
        this.validationsAdicional.push({"adicionalCurrent.fnac" : "este campos es requerido."});


    if(!this.isNullOrEmpty(this.adicionalCurrent.fnac)){

      let b = moment(this.adicionalCurrent.fnac, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 14)       
        this.validationsAdicional.push({"adicionalCurrent.fnac" : "no se permite menor a 14 años"})
    }         
    
    if(this.isNullOrEmpty(this.adicionalCurrent.nombre1))
       this.validations.push({"datos_personales.nombre_primero" : "este campos es requerido."})
   

    if(this.isNullOrEmpty(this.adicionalCurrent.apellido))
        this.validationsAdicional.push({"adicionalCurrent.nombre_completo" : "este campos es requerido."});


    if(this.isNullOrEmpty(this.adicionalCurrent.nombreCompleto))
        this.validationsAdicional.push({"adicionalCurrent.nombre_primero" : "este campos es requerido."});


    if(this.vinculosList.data.find(v=>v.key==this.adicionalCurrent.vinculo) == null){
      this.adicionalCurrent.vinculo = "";
      this.validationsAdicional.push({"adicionalCurrent.vinculo" : "este campos es requerido."})
    }  


    if(this.generosList.data.find(v=>v.key==this.adicionalCurrent.genero) == null){
      this.adicionalCurrent.genero = "";
      this.validationsAdicional.push({"adicionalCurrent.genero" : "este campos es requerido."})
    }       

    return this.validationsAdicional.length == 0;
  }

  public validationsAdcionalMessage(name):string{
    if(!this.validationsAdicionalShow)
      return null;

    this.validationsAdcionalForm();

    let validation = this.validationsAdicional.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }


  alertaPermisosExportar(){
    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}
  }  

  export(){

    this.alertaPermisosExportar();

    let reportExport = new Array<any>();

    this.docrecoveryData.forEach(item => {

      if(item.variables_salida == null)
         item.variables_salida = []

      let objRow: any = new Object;
      objRow["NOMBRE Y APELLIDO"] = item.intSocioData.cardName;
      objRow["CUIL"] = item.cuil;
      objRow["DNI"] = item.intSocioData.dni;
      objRow["PCN"] = item.pcn;
      objRow["FECHA"] = item.fechaArchivo;
      objRow["ULTIMA MODIF"] = item.fechaModificacion;
      objRow["PRODUCTO"] = item.productoObj.data.prod+ " - " +item.productoObj.value;
      objRow["ESTADO"] = item.motivo;

      for( var i = 0; i < item.intPersonaSuples.length; i++ ){
        let obSup = item.intPersonaSuples[ i ];
        objRow["dni_sup_"+( i+1 )] = obSup.docNumSuple;
        objRow["nombre_sup_"+( i+1 )] = ( !Utils.isNullOrEmpty( obSup.nombreCompleto ) ) ? obSup.nombreCompleto : obSup.nombre1 + " "+obSup.apellido ;
        objRow["estado_sup_"+( i+1 )] = obSup.accion;//( obSup.denegado ) ? "Denegado" : "Aprobado";
        objRow["motivo_sup_"+( i+1 )] = obSup.motivo;
      }
      
      reportExport.push( objRow );

    });

    this.commonDataService.exportAsExcelFile(reportExport, this.title);

  }  

  
  exportReporteDO(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    let docrecoveryDO = this.docrecoveryData.filter(f => f.motivo == "A ESPERA DE INFORMACION");

    docrecoveryDO.forEach(item => {    
   
      if( !Utils.isNullOrEmpty( item.intSocioData.profession ) ){
        var profession = this.profesionesList.data.filter(data => data.key == item.intSocioData.profession);
        if( !Utils.isNullOrEmpty (profession)){
          item.intSocioData.profession = profession[0].value
        }

      }
      let objRow: any = new Object;
      objRow["PCN"] = item.pcn;
      objRow["NOMBRE"] = item.intSocioData.firstName;
      objRow["APELLIDO"] = item.intSocioData.lastName;
      objRow["PRODUCTO"] = item.internalSourceCode.nombreProducto;
      objRow["PROMOTOR"] = item.informedPromotor;
      objRow["NACIONALIDAD"] = item.intSocioData.nationality;
      objRow["TELEFONO"] = item.intSocioData.tel1;
      objRow["EMAIL"] = item.intSocioData.email;
      objRow["DNI"] = item.intSocioData.dni;
      objRow["CUIL"] = item.cuil;
      objRow["FECHA DE NACIMIENTO"] = item.intSocioData.birthDate;
      objRow["PROFESION"] = item.intSocioData.profession;
      objRow["DIRECCION"] = item.intSocioData.street;

      for( var i = 0; i < item.intPersonaSuples.length; i++ ){
        let obSup = item.intPersonaSuples[ i ];
        objRow["nombre_sup_"+( i+1 )] = ( !Utils.isNullOrEmpty( obSup.nombreCompleto ) ) ? obSup.nombreCompleto : obSup.nombre1 + " "+obSup.apellido ;
        objRow["dni_sup_"+( i+1 )] = obSup.docNumSuple;
        objRow["cuil_sup_"+( i+1 )] = obSup.nosisCuil;
      }

      reportDataExport.push(objRow);
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, "REPORTE DO CUSTOMER");

  }
  
  showButtonReporteDO():boolean{
    let roles = ['AMX_INT_ADMIN',
      'AMX_INT_AG_SUPER',
      'AMX_INT_RD_SUPER'];            

    return this.sessionStorageService.hasRolesUser(roles);
  }


  public isNullOrEmpty(value:any):boolean{
  
      return value==null || value == "";
  };

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observacion=this.sanitizedText(this.aplicanteCurrent.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.intSocioData.postalCode=this.sanitizedTextCp(this.aplicanteCurrent.intSocioData.postalCode); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}

listProvinceLoad(){
  
  this.internalDocrecoveryService.getListProvince().subscribe(
  response => {
    if( response.status == 200 && response.body != null){
      var data = response.body;
      this.provinceData = data['data']

    }else if( response.status == 204 ){
      this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
    }else{
      this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
    }

    this.loading = false;
    },
  );
}

listVinculosLoad(){
  
  this.internalDocrecoveryService.getVinculos().subscribe(
  response => {
    if( response.status == 200 && response.body != null){
      var data = response.body;
      this.vinculosData = data['data']

    }else if( response.status == 204 ){
      this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
    }else{
      this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
    }

    this.loading = false;
    },
  );
}

fillTipodeVenta(){
  this.tipoDeVentaList.push("RCO")
  this.tipoDeVentaList.push("SUP")
  this.tipoDeVentaList.push("CON")
}


getSourceCodeRecovery(item){


  let request = {"pcn" : item}

  if(this.aplicanteCurrent.internalSourceCode.sourceCodeCompleto== null){
  this.internalDocrecoveryService.getSourceCodes(request).subscribe(
    data => {
    
        
        this.sourceCodes = new InternalSourceCode();
        this.sourceCodes = Object.assign(this.sourceCodes, data);
        
     
         
      
    
  },
  error => { 
    console.error(error);
  }
  );   } else{
    this.sourceCodes = this.aplicanteCurrent.internalSourceCode;
  } 

  return this.sourceCodes;
}




public rellenar(item){
  
  if(item != "Seleccionar"){  
    
    this.aplicanteCurrent.internalSourceCode= this.sourceCodes;  
    this.sourcecodesListForApplicant = [];
    
      if( !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery1 ) && !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery2 ) ){
          this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery1 );
          this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery2 );
          this.aplicanteCurrent.deliveryCd = this.aplicanteCurrent.internalSourceCode.delivery1;
      }
        
  }
   else {
    
    this.aplicanteCurrent.internalSourceCode= item; 
    this.aplicanteCurrent.deliveryCd = item;
      
  
  }
  }
  
}


