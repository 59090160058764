import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { RouterModule, Routes} from '@angular/router';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { Config } from './config/config';
import { ConfigLocal } from './config/config-local';
import { ConfigTest } from './config/config-test';
import { ConfigProd } from './config/config-prod';

import { AppComponent } from './app.component';


//MODULES
import {SidebarModule} from 'primeng/sidebar';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {MenuModule} from 'primeng/menu';

//COMPONENTS
import { BaseComponent } from './component/shared/base.component';

import { HomeComponent } from './component/home/home.component';
import { LoadingComponent } from './component/shared/loading/loading.component';
import { PreCheckComponent } from './component/precheck/precheck.component';
import { DocRecoveryComponent } from './component/docrecovery/docrecovery.component';

import { AlertComponent } from './component/shared/alert/alert.component';
import { ExcelFileComponent } from './component/excelFile/excelFile.component';

import { NewAccountsComponent } from './component/newAccounts/newAccounts.component';
import { TelemarketingComponent } from './component/telemarketing/telemarketing.component';
import { DeliveryComponent } from './component/delivery/delivery.component';
import { MasiveDocrecoveryComponent } from './component/masiveDocrecovery/masiveDocrecovery.component';
import { MasiveTelemarketingComponent } from './component/masiveTelemarketing/masiveTelemarketing.component';
import { MasiveDeliveryComponent } from './component/masiveDelivery/masiveDelivery.component';

import { ApplicantDetailComponent } from './component/applicantDetail/applicantDetail.component';
import { ProductsComponent } from './component/products/products.component';
import { SourceCodesComponent } from './component/sourcecodes/sourcecodes.component';
import { VersionInfoComponent } from './component/versionInfo/versionInfo.component';

import { AyudaComponent } from './component/ayuda/ayuda.component';



//Services:
import { DeliveryService } from './services/delivery/delivery.service';
import { SessionStorageService} from "./auth/services/session.service";
import { CommonDataService} from "./services/commons/commondata.service";
import { PreCheckService } from './services/precheck/precheck.service';
import { DocRecoveryService } from './services/docrecovery/docrecovery.service';
import { TelemarketingService } from './services/telemarketing/telemarketing.service';
import { NewAccountsService } from './services/newAccounts/newAccounts.service';
import { ApplicantDetailService } from './services/applicantDetail/applicantDetail.service';

import { AgenciesService } from './services/agencies/agencies.service';
import { ReportTotalService } from './services/reportTotal/reportTotal.service';
import { SourceCodesService } from './services/sourcecodes/sourcecodes.service';
import { VersionInfoService } from './services/versionInfo/versioninfo.service';


import { InterceptorGuard } from './auth/guards/interceptor.guard';
import { AuthorizatedGuard } from './auth/guards/authorizated.guard';

//Directives:
import { HtmlInputOnlyNumber } from './directives/htmlinput.onlynumber';
import { HtmlInputOnlyWords } from  './directives/htmlinput.onlywords';

import { MenuComponent } from './component/shared/menu/menu.component';
import { UsersService } from './services/users/users.service';
import { ReportTotalComponent } from './component/reportTotal/reportTotal.component';
import { ProfessionsComponent } from './component/professions/professions.component';
import { ProfessionsService } from './services/professions/professions.service';
import { CpidsComponent } from './component/cpids/cpids.component';
import { CpidsService } from './services/cpids/cpids.service';
import { RangosIngresoComponent } from './component/rangosIngreso/rangosIngreso.component';
import { RangosIngresoService } from './services/rangosIngreso/rangosIngreso.service';
import { ExcelExportService } from './services/excelExport/excel.export.service';
import { ExcelFilesService } from './services/excelFiles/excelFiles.service';
import { ReportAgenciesService } from './services/reportAgencies/reportagencies.service';
import { ReportAgenciesComponent } from './component/reportAgencies/reportagencies.component';

import { ReportStatusService } from './services/reportStatus/reportstatus.service';
import { ReportStatusComponent } from './component/reportStatus/reportstatus.component';

import { ChangeStatusService } from './services/changeStatus/changeStatus.service';
import { ChangeStatusComponent } from './component/changeStatus/changeStatus.component';
import { ReprocessApplicantComponent } from './component/reprocessApplicant/reprocessApplicant.component';
import { ReprocessApplicantExcepcionComponent } from './component/reprocessApplicantExcepcion/reprocessApplicantExcepcion.component';
import { ReprocessApplicantService } from './services/reprocessApplicant/reprocessApplicant.service';
import { ShortLongFormService } from './services/shortLongForm/shortLongForm.service';
import { MasiveShortLongFormComponent } from './component/masiveShortLongForm/masiveShortLongForm.component';
import { MasiveTelemarketingChangeStatusComponent } from './component/masiveTelemarketingChangeStatus/masiveTelemarketingChangeStatus.component';

import { ParametersComponent } from './component/parameters/parameters.component';
import { ParametersService } from './services/parameters/parameters.service';
import { ChangePromotorComponent } from './component/changePromotor/changePromotor.component';
import { ChangePromotorService } from './services/changePromotor/changePromotor.service';
import { BridgerStatusComponent } from './component/bridgerStatus/bridgerStatus.component';
import { BridgerStatusService } from './services/bridgerStatus/bridgerStatus.service';
import { ApplicantHistoryComponent } from './component/applicantHistory/applicantHistory.component';
import { NormalizePatternsComponent } from './component/normalizePatterns/normalizePatterns.component';
import { NormalizePatternsService } from './services/normalizePatterns/normalizePatterns.service';
import { CreditLimitMatrixComponent } from './component/creditLimitMatrix/creditLimitMatrix.component';
import { CreditLimitMatrixService } from './services/creditLimitMatrix/credit-limit-matrix.service';

import { ReportApplicantsComponent } from './component/reportApplicants/report-applicants.component';
import { ReportApplicantsService } from './services/reportApplicants/report-applicants.service';
import { ParametersIncomeNseComponent } from './component/parametersIncomeNse/parametersIncomeNse.component';


// Internals Components
import { InternalDocRecoveryComponent } from './component_internal/internalDocrecovery/internalDocrecovery.component';
import { InternalNewAccountsComponent } from './component_internal/internalNewAccounts/internalNewAccounts.component';
import { InternalCustomerDetailComponent } from './component_internal/internalCustomerDetail/internalCustomerDetail.component';
import { InternalSourceCodesComponent } from './component_internal/internalSourcecodes/internalSourcecodes.component';
import { InternalMassiveTelemarketingComponent } from './component_internal/internalMassiveTelemarketing/internalMassiveTelemarketing.component';
import { InternalCustomerHistoryComponent } from './component_internal/internalCustomerHistory/internalCustomerHistory.component';
import { InternalDeliveryComponent } from './component_internal/internalDelivery/internalDelivery.component';
import { InternalTelemarketingComponent } from './component_internal/internalTelemarketing/internalTelemarketing.component';
import { InternalExcelFileComponent } from './component_internal/internalExcelFile/internalExcelFile.component';
import { InternalPreCheckComponent } from './component_internal/internalPrecheck/internalPrecheck.component';
import { InternalMassiveDocRecoveryComponent } from './component_internal/internalMassiveDocRecovery/internalMassiveDocRecovery.component';
import { InternalReprocessApplicantComponent } from './component_internal/internalReprocessApplicant/internalReprocessApplicant.component';
import { InternalBridgerStatusComponent } from './component_internal/internalBridgerStatus/internalBridgerStatus.component';
import { InternalMassiveDeliveryComponent } from './component_internal/internalMassiveDelivery/internalMassiveDelivery.component'; 
import { InternalSuplesRejectedComponent } from './component_internal/internalSuplesRejected/internalSuplesRejected.component';
import { InternalChangeStatusComponent } from './component_internal/internalChangeStatus/internalChangeStatus.component';
import { InternalVersionInfoComponent } from './component_internal/internalVersionInfo/internalVersionInfo.component';


//Internal Services
import { InternalCustomerDetailService } from './services_internal/internalCustomerDetail/internalCustomerDetail.service';
import { InternalSourceCodesService } from './services_internal/internalSourcecodes/internalSourcecodes.service';
import { InternalDocRecoveryService } from './services_internal/internalDocrecovery/internalDocrecovery.service';
import { InternalNewAccountsService } from './services_internal/internalNewAccounts/internalNewAccounts.service';
import { InternalCustomerHistoryService } from './services_internal/internalCustomerHistory/internalCustomerHistory.service';
import { InternalDeliveryService } from './services_internal/internalDelivery/internalDelivery.service';
import { InternalTelemarketingService } from './services_internal/internalTelemarketing/internalTelemarketing.service';
import { InternalMassiveDeliveryService } from './services_internal/internalMassiveDelivery/internalMassiveDelivery.service';
import { InternalChangeStatusService } from './services_internal/internalChangeStatus/internalChangeStatus.service';
import { InternalVersionInfoService } from './services_internal/internlVersionInfo/internalVersionInfo.service';

//login
import { LoginComponent } from './component/login/login.component';

import { ModifyUserComponent } from './component/modifyUser/modifyUser.component';
import { ModifyUserService } from './services/modifyUser/modifyUser.service';
//nuevos reportes
import { InternalReportApplicantsComponent } from './component_internal/internalReportApplicants/internalReportApplicants.component';
import { InternalReportApplicantsService } from './services_internal/internalReportApplicants/internalReportApplicants.service';

import { InternalReportAgenciesComponent } from './component_internal/internalReportAgencies/internalReportAgencies.component';
import { InternalReportAgenciesService } from './services_internal/internalReportAgencies/internalReportAgencies.service';
//abm users
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AbmUsersComponent } from './component/abmUsers/abmUsers.component';
import { AbmUsersService } from './services/abmUsers/abmUsers.service';

//abm ciclos
import { InternalAbmCycleComponent } from './component_internal/internalAbmCycle/internalAbmCycle.component';
import { InternalAbmCycleService } from './services_internal/internalAbmCycle/internalAbmCycle.service';

const appRoutes: Routes = [
  
  {path: '', component: HomeComponent, 
             pathMatch: 'full',
             canActivate: [ AuthorizatedGuard ]},
  {path: 'precheck', component: PreCheckComponent},
  {path: 'masiveDocrecovery', component: MasiveDocrecoveryComponent },
  {path: 'masiveTelemarketing', component: MasiveTelemarketingComponent }, 
  {path: 'masiveDelivery', component: MasiveDeliveryComponent },  
  {path: 'masiveChangeStatusTelemarketing', component: MasiveTelemarketingChangeStatusComponent },  
  {path: 'masiveShortLongForm', component: MasiveShortLongFormComponent },   
  {path: 'excelFile', component: ExcelFileComponent },
  {path: 'docrecovery', component: DocRecoveryComponent },
  {path: 'newAccounts', component: NewAccountsComponent },  
  {path: 'telemarketing', component: TelemarketingComponent },
  {path: 'delivery', component: DeliveryComponent },
  {path: 'applicantDetail', component: ApplicantDetailComponent },
  {path: 'products', component: ProductsComponent},
  {path: 'reportTotal', component: ReportTotalComponent},
  {path: 'professions', component: ProfessionsComponent},
  {path: 'cpids', component: CpidsComponent},
  {path: 'sourcecodes', component: SourceCodesComponent},
  {path: 'versionInfo' , component: VersionInfoComponent},
  {path: 'rangosIngreso', component: RangosIngresoComponent},
  {path: 'reportAgencies', component: ReportAgenciesComponent},
  {path: 'reportStatus', component: ReportStatusComponent},  
  {path: 'changeStatus', component: ChangeStatusComponent},  
  {path: 'reprocessApplicant', component: ReprocessApplicantComponent}, 
  {path: 'parameters', component: ParametersComponent},  
  {path: 'changePromotor', component: ChangePromotorComponent},  
  {path: 'bridgerStatus', component: BridgerStatusComponent},  
  {path: 'applicantHistory', component: ApplicantHistoryComponent},  
  {path: 'reportApplicants', component: ReportApplicantsComponent},	
  {path: 'normalize-patterns', component: NormalizePatternsComponent},
  {path: 'credit-limit-matrix', component: CreditLimitMatrixComponent},
  {path: 'reprocessApplicantException', component: ReprocessApplicantExcepcionComponent},
  {path: 'nseIncome', component: ParametersIncomeNseComponent},

  {path: 'internal-delivery', component: InternalDeliveryComponent },
  {path: 'internal-telemarketing', component: InternalTelemarketingComponent },
  {path: 'internalCustomerDetail', component: InternalCustomerDetailComponent },


  {path: 'internal-sourceCodes', component: InternalSourceCodesComponent},
  {path: 'internal-docRecovery', component: InternalDocRecoveryComponent },
  {path: 'internal-newAccounts', component: InternalNewAccountsComponent }, 
  {path: 'internal-customerDetail', component: InternalCustomerDetailComponent },
  {path: 'internal-massiveTelemarketing', component: InternalMassiveTelemarketingComponent }
  ,{path: 'internal-customerHistory', component: InternalCustomerHistoryComponent },
  {path: 'internal-excelFile', component: InternalExcelFileComponent }
  ,{path: 'internal-precheck', component: InternalPreCheckComponent },
  {path: 'internal-massiveDocRecovery', component: InternalMassiveDocRecoveryComponent }
  ,{path: 'internal-reprocessApplicantException', component: InternalReprocessApplicantComponent }
  ,{path: 'internal-reprocessApplicant', component: InternalReprocessApplicantComponent },
  {path: 'internal-bridgerStatus', component: InternalBridgerStatusComponent },
  {path: 'internal-massiveDelivery', component: InternalMassiveDeliveryComponent },
  {path: 'internal-suplesRejected' , component: InternalSuplesRejectedComponent},
  {path: 'internal-changeStatus' , component: InternalChangeStatusComponent},
  {path: 'internal-versionInfo' , component: InternalVersionInfoComponent}


  ,{path: 'login', component: LoginComponent }
  ,{path: 'modifyUser', component: ModifyUserComponent }
  ,{path: 'internalReportApplicants', component: InternalReportApplicantsComponent }
  ,{path: 'internalReportAgencies', component: InternalReportAgenciesComponent }
  ,{path: 'abmUsers', component: AbmUsersComponent }
  ,{path: 'internalAbmCycle', component: InternalAbmCycleComponent }
  ,{path: 'ayuda', component: AyudaComponent}
];

@NgModule({
  declarations: [
    //Components:
    BaseComponent,
    AppComponent,
    HomeComponent,    
    LoadingComponent, 
    PreCheckComponent,
    DocRecoveryComponent,
    MasiveShortLongFormComponent,
    MasiveDocrecoveryComponent,
    MasiveTelemarketingComponent,
    MasiveDeliveryComponent,
    ExcelFileComponent,
    NewAccountsComponent,
    InternalNewAccountsComponent,
    AlertComponent,
    TelemarketingComponent,
    DeliveryComponent,
    ApplicantDetailComponent,
    //AgenciesComponent,
    //Directives: Begin:
    HtmlInputOnlyNumber,
    HtmlInputOnlyWords,
    //Directives: End:
    ApplicantDetailComponent,
    MenuComponent,
    //UsersComponent,
    ProductsComponent,
    ReportTotalComponent,
    ProfessionsComponent,
    CpidsComponent,
    SourceCodesComponent,
    VersionInfoComponent,
    RangosIngresoComponent,
    ReportAgenciesComponent,
    ReportStatusComponent, 
    ChangeStatusComponent, 
    ReprocessApplicantComponent,
    MasiveTelemarketingChangeStatusComponent,
    ParametersComponent,
    ChangePromotorComponent,
    BridgerStatusComponent,
    ApplicantHistoryComponent,
    ReportApplicantsComponent,
    CreditLimitMatrixComponent,
    NormalizePatternsComponent,
    ReprocessApplicantExcepcionComponent,
    ParametersIncomeNseComponent,
    InternalDeliveryComponent,
    InternalTelemarketingComponent,
    InternalCustomerDetailComponent,
    InternalSourceCodesComponent,
    InternalNewAccountsComponent,
    InternalDocRecoveryComponent,
    InternalMassiveTelemarketingComponent,
    InternalExcelFileComponent,
    InternalCustomerHistoryComponent
    ,InternalPreCheckComponent,
    InternalMassiveDocRecoveryComponent
    ,InternalReprocessApplicantComponent,
    InternalMassiveDeliveryComponent,
    InternalSuplesRejectedComponent,
    InternalChangeStatusComponent,
    InternalVersionInfoComponent,
    InternalBridgerStatusComponent
    ,LoginComponent
    ,ModifyUserComponent
    ,InternalReportApplicantsComponent
    ,InternalReportAgenciesComponent
    ,AbmUsersComponent
    ,InternalAbmCycleComponent
    ,AyudaComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
    //DataGridModule,
    ButtonModule,
    DialogModule,
    SidebarModule,
    //TabViewModule,
    //OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    PanelMenuModule,
    MenuModule,
    CalendarModule,
    DragDropModule,
    //DataTablesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorGuard,
      multi: true
    },
    HttpClient,
    // { provide: APP_BASE_HREF, useValue : '/ui' },
    InterceptorGuard,
    AuthorizatedGuard,
    Config,
    ConfigLocal,
    ConfigTest,
    ConfigProd,
    DatePipe,       
    ExcelExportService,
    PreCheckService,
    DocRecoveryService,
    InternalDocRecoveryService,
    TelemarketingService,
    InternalTelemarketingService,
    InternalMassiveDeliveryService,
    NewAccountsService,
    InternalDeliveryService,
    DeliveryService,
    ApplicantDetailService,
    SessionStorageService,
    CommonDataService,
    AgenciesService,
    UsersService,   
    ReportTotalService,
    ProfessionsService,
    CpidsService,
    RangosIngresoService,
    ExcelFilesService,
    ReportAgenciesService,
    ReportStatusService,
    SourceCodesService,
    VersionInfoService,
    ChangeStatusService,
    ReprocessApplicantService,
    ShortLongFormService,
    ParametersService,
    ChangePromotorService,
    BridgerStatusService,
    ReportApplicantsService,
    NormalizePatternsService,
    CreditLimitMatrixService
    ,InternalCustomerDetailService
    ,InternalSourceCodesService
    ,InternalCustomerHistoryService,
    InternalNewAccountsService
    ,ModifyUserService
    ,InternalReportApplicantsService
    ,InternalReportAgenciesService
    ,AbmUsersService
    ,InternalAbmCycleService,
    InternalVersionInfoService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { 
}

