import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class ApplicantDetailService {


    constructor(private http: HttpClient, private Conf: Config) { 
    }

    public getData(data){

 

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantDetail';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);   

    }

 
    public getHistory(data){

 

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'findByDocNum';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);   

    }

}