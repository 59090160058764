import { AbstractType, Component, OnInit } from '@angular/core';

import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { DocRecoveryService } from '../../services/docrecovery/docrecovery.service';
import { AplicantModel, DatosPersonalesModel, 
         DatosContactoModel, DatosLaboralesModel,
         DireccionModel, TelefonoModel, ObservacionesModel,
         EntregaDocumentacionModel, ObservacionesTackingModel, 
         ValidacionModel, AplicanteAdicionalModel, BusquedaFiltrosModel,         
        } from 'src/app/models/aplicante.model';

import {  GenerosList, TiposDocumentosList, VinculosList,
          NacionalidadesList, EstadosCivilList, ProvinciasList,
          TiposViviendasList, EducacionNivleslList, ProfesionesList,
          TrabajosTipolList, ProductosList, CargoslList,
          AccionAplicanteList, MotivosAplicanteList, HorasList, SourceCodesList} 
          from 'src/app/models/lists.model';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { CRUD, Environment } from 'src/app/config/environment';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import * as moment from 'moment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BaseComponent } from '../shared/base.component';
import { Roles } from 'src/app/auth/models/roles.static';

@Component({
  selector: 'app-docrecovery',
  templateUrl: './docrecovery.component.html',
  styleUrls: ['./docrecovery.component.css']  
})

export class DocRecoveryComponent  extends BaseComponent  implements OnInit {

  
  public es: any;
  public title: string = "RECUPERO DE DOCUMENTACIÓN";
  public showResult : boolean;
  public tablereports = null;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  
  
  
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean; 


  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;


  public docrecoveryData = [];
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public aplicanteCurrentBk: AplicantModel = new AplicantModel;
  
  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
  public adicionalCurrentBk: AplicanteAdicionalModel = new AplicanteAdicionalModel;

  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validationsAdicional: Array<any> = new Array<any>();
  public validationsAdicionalShow: boolean; 
  public fechaRellamadoDisabled: boolean = true;

  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();

  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public cargosList: CargoslList = new CargoslList();
  public horasList: HorasList = new HorasList();
  public sourcecodesList: SourceCodesList = new SourceCodesList();
  public productsApplicationSource: any = {data:[]};
  public rolConsulta : boolean=true;
  fecha_rellamado_mindate: string;

  constructor(private commonDataService: CommonDataService,
              private docrecoveryService: DocRecoveryService, 
              private sessionStorageService: SessionStorageService) {
                super();
                this.sessionStorageService.rolValidate("docrecovery");
              }

  ngOnInit() {
    
    
    this.fecha_rellamado_mindate = new Date().getFullYear().toString() + ":2050";
    this.busquedafiltros.producto = "";     
     
    
    this.setCalendarLocale();
    this.validationAccionLoad();   
    this.profesionesLoad(); 
    this.loadProductos();
    this.loadSourcecodes();
    this.rolesAutorization();
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  loadReportResults(){
    
    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
      Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true);
        return;
      }  
    }
    this.loading = true;
    this.loadReportRequest();
    return true;
  }

  loadReportRequest(){
    
    let request = { 
        "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
        "dni": Utils.parseEmptyNull(this.busquedafiltros.dni), 
        "fecha": Utils.parseEmptyNull(this.busquedafiltros.fecha), 
        "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre), 
        "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn), 
        "producto": Utils.parseEmptyNull(this.busquedafiltros.producto),
        "fechaDesde": Utils.parseEmptyNull(this.busquedafiltros.fechaDesde),
        "fechaHasta": Utils.parseEmptyNull(this.busquedafiltros.fechaHasta)
    };         

    this.docrecoveryService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {
          this.docrecoveryData = data['data'];
                    
          this.docrecoveryData.forEach(i=>{
            i.producto = this.productosList.data.find(p=>p.key == (i.producto??""))?.value ?? "";
          })
         
          
          this.setupTable();             

        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );

    return true;
  }

  showAplicante(pcn:string){
    this.loading = true;

      let request = {
        "pcn":pcn
      };

      this.docrecoveryService.getApplicantDataByArea(request).subscribe(
        data => {

          if (data && data['code'] == "100"){
            this.alertMessage = new AlertModel(data['message'] , this.title, true);
            this.loading = false;
            return;
          }

          if (data && data.data) {

            if(data.data.length == 0){
              this.loading = false;
              this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
              return;
            }

            if(!Utils.isNullOrEmpty(data.data[0].datos_personales.fecha_nacimiento)){

              if(!Utils.dateIsValid(data.data[0].datos_personales.fecha_nacimiento)){
               
                if(Utils.dateIsValid(data.data[0].datos_personales.fecha_nacimiento.split(" ")[0])){
                  data.data[0].datos_personales.fecha_nacimiento = data.data[0].datos_personales.fecha_nacimiento.split(" ")[0];
                }else
                  data.data[0].datos_personales.fecha_nacimiento = "";
                
              }
            }


            
            this.aplicanteCurrent = Object.assign(this.aplicanteCurrent, data.data[0]);
            if( this.aplicanteCurrent.observaciones_tracking.length ){
              this.aplicanteCurrent.observaciones_tracking.sort(function(a, b) {
                var textA = a.fecha;
                var textB = b.fecha;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
            }
            this.aplicanteCurrent.observaciones.observacion = "";

            let accion = this.accionAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.accion);
            
            if(accion){
              this.aplicanteAccionChange(this.aplicanteCurrent.validacion.accion);
            } else{
              
              this.accionAplicanteList.data.push({
                          "key":this.aplicanteCurrent.validacion.accion,
                          "value":this.aplicanteCurrent.validacion.accion });

              this.motivosAplicanteList.data.push({
                          "key":this.aplicanteCurrent.validacion.motivo,
                          "value":this.aplicanteCurrent.validacion.motivo,
                          "parent":this.aplicanteCurrent.validacion.accion });                          
            }    

            this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{

              if(!Utils.dateIsValid(a.fecha_nacimiento)){
                a.fecha_nacimiento = null;
              }

              return !Utils.isNullOrEmpty(a.numero_documento);
            });

            if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.hora_rellamado))
               this.aplicanteCurrent.detalles.hora_rellamado = "";

            if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.estado_civil))
               this.aplicanteCurrent.datos_personales.estado_civil = "NA";

            this.aplicanteCurrent.datos_personales.cantRellamadosRec = this.aplicanteCurrent.datos_personales.cantRellamadosRec;
            this.aplicanteCurrent.detalles.cantidad_suples = this.aplicanteCurrent.adicionales.length.toString();
            this.aplicanteCurrent.datos_personales.nombre_completo = this.aplicanteCurrent.datos_personales.nombre_completo.trim();
            this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
            this.validationsShow = false;
            this.showAplicanteDetalles = true;
            this.showResult = false
            this.loading = false;
            this.refactorCp();
            this.setupTootip();
            window.scroll(0, 0);
            this.direccionBinding()
            this.loadDataRolConsulta(pcn);
            this.commonDataService.pingApplicantHoldTimerStart(pcn);
          }
          else {
            console.error(data);
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");          
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
        }
      );      
      
      return true;
  
  }

  public loadDataRolConsulta(pcn:string){
    let request = {
      "pcn" : pcn
    }
    this.docrecoveryService.getApplicantDataByArea(request).subscribe(
      data => {
        this.productsApplicationSource.data = data["data"]
        if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent) == null)
        this.disableInputRolConsulta()
      },
        
      error => console.error(error));   
  }

  public disableInputRolConsulta(){
  
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")     
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")
      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
  if (rolesSession.includes("AMX_CONSULTA")){
    this.rolConsulta=false
  }
}

  listAplicanteDireccinesTelefonos(origen:string){

    let data = Array<any>();
    let direciones = this.aplicanteCurrent.direcciones.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let telefonos = this.aplicanteCurrent.telefonos.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let length = (direciones.length >= telefonos.length) ? direciones.length : telefonos.length;

    for(let i = 0; i < length; i++){
      data.push({
        "direccion" : direciones[i] != null ? direciones[i].direcciones : "",
        "telefono" : telefonos[i] != null ? telefonos[i].telefono : "",
      });   
    }
   
    return data;
  }

  confirmSave(){

    if(!this.rolConsulta){
      this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
      return;
    } 

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    }
    
    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveDocRecoveryAplicante();
    }
  }

  saveDocRecoveryAplicante(){

    this.validationsShow = false;      

    let aplicante: any = new Object;

    aplicante.usuario = this.sessionStorageService.getCurrentUser()?.username;    
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;
    
    aplicante.solicitud_tarjeta = this.aplicanteCurrent.solicitud_tarjeta;
    aplicante.entrega_documentacion = this.aplicanteCurrent.entrega_documentacion;
    aplicante.datos_personales = this.aplicanteCurrent.datos_personales;
    aplicante.datos_contacto = this.aplicanteCurrent.datos_contacto;
    aplicante.datos_laborales = this.aplicanteCurrent.datos_laborales;
    aplicante.adicionales = this.aplicanteCurrent.adicionales;
    aplicante.observaciones = this.aplicanteCurrent.observaciones;
    aplicante.validacion = this.aplicanteCurrent.validacion;    
    aplicante.detalles = this.aplicanteCurrent.detalles;
    console.log(aplicante);
    this.saveAplicante(aplicante);
  }

  public saveAplicante(aplicante) {

    this.loading = true;    

    this.docrecoveryService.saveApplicantDataByArea(aplicante).subscribe(
      data => {
        if (data != null && data['code'] == "0"){
          
          this.loading = false;          
          this.showAplicanteDetalles = false;
          this.showResult = true;          
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
          window.scroll(0, 0);   
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		
      },
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		

        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
      }
    );     

    return true;
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }

 

  adicionalesList(){

    let adicionales = this.aplicanteCurrent.adicionales.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

    return adicionales;
  }

  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == dni;
    });

    let adicionalBk = this.aplicanteCurrentBk.adicionales.find((a)=>{
      return a.numero_documento == dni;
    });

    this.adicionalCurrent  = Object.assign({}, adicional);
    this.adicionalCurrentBk = adicional;
    this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

    this.showAdicionalesEditar = true;
    
    return true;
  }

  adicionalesNuevo() {

    let adicionales = this.aplicanteCurrent.adicionales.filter(a=>a.crud != CRUD.DELETE);
    if(adicionales.length >= 5){
      this.alertMessage = new AlertModel("Solo se permiten hasta 5 adicionales.", this.title,true, ALERT_TYPE.INFO);	
      return false;
    }

     this.adicionalCurrent  = new  AplicanteAdicionalModel();
     this.adicionalCurrentBk  = null;
     this.adicionalCurrent.crud = CRUD.CREATE;     
     this.showAdicionalesEditar = true;

     return true;
 }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
    this.validationsAdicionalShow = false;
    this.validationsAdicional = [];
  }

  adicionalesGuardar(){

    if(!this.validationsAdcionalForm()){
      this.validationsAdicionalShow = true;
      return false;
    }
    
    this.adicionalCurrent.numero_documento = this.adicionalCurrent.numero_documento.replace(/[^0-9]+/,"");

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{
      return a.numero_documento == this.adicionalCurrent.numero_documento &&
             a.crud != CRUD.DELETE;;
    });
    
    let dniChangeCurrent = adicional && !this.adicionalCurrentBk ? adicional.numero_documento == this.adicionalCurrent.numero_documento: false;
    let dniChangeOriginal = adicional && this.adicionalCurrentBk ? this.adicionalCurrentBk.numero_documento != this.adicionalCurrent.numero_documento: false;
    
    if(dniChangeCurrent  || dniChangeOriginal){
      this.alertMessage = new AlertModel("Ya existe un adicional con el mismo número documento.", this.title,true, ALERT_TYPE.INFO);	
      return false;      
    }

    
    if(adicional || this.adicionalCurrentBk) {
        let index = this.aplicanteCurrent.adicionales.indexOf(adicional ?? this.adicionalCurrentBk);

        this.aplicanteCurrent.adicionales[index] = Object.assign({},  this.adicionalCurrent );
        this.aplicanteCurrent.adicionales[index].usuario = this.sessionStorageService.getCurrentUser().username;
        
    }else{
      this.adicionalCurrent.usuario = this.sessionStorageService.getCurrentUser().username;
      this.aplicanteCurrent.adicionales.push(this.adicionalCurrent);
      
    }

    this.adicionalCurrentBk = null;
    this.showAdicionalesEditar = false;
    return true;

  }

  adicionalesBorrarConfirm(confirm:any){

    if(confirm.confirm){
      let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

        return a.numero_documento == confirm.data;
      });
  
     let index =  this.aplicanteCurrent.adicionales.indexOf(adicional);

     this.aplicanteCurrent.adicionales[index].crud = CRUD.DELETE;;
    }
  }

  
  adicionalesBorrar(dni:any){

    this.alertConfirmEvent = this.adicionalesBorrarConfirm;
    this.alertMessage = new AlertModel("Esta acción eliminará el siguiente registro: " + dni + "\nEsta seguro de continuar?",this.title,true, "CONFIRM", dni);	
        
  }

  validationAccionLoad(){

    this.docrecoveryService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;

  }

  
  profesionesLoad(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        }        
        else 
          console.error(data);  
          
        
      },
      error => { 
        console.error(error);
      }
    );

    return true;

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }


      },
      error => {
        console.error(error);
      }
    );

    
    

  }

  loadSourcecodes(){

    this.commonDataService.getSourcecodes().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.sourcecodesList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    ); 

  }



  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.docrecoveryService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.motivo);
          if(motivo == null){
            this.aplicanteCurrent.validacion.motivo = "";
          }


          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  
          
          this.aplicanteMotivoChange(this.aplicanteCurrent.validacion.motivo);
            
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        console.error(error + "😬");
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }

  aplicanteMotivoChange(item){
   
    if(this.aplicanteCurrent.validacion.accion == "PRECHECK COMPLETO" ){
        this.fechaRellamadoDisabled = false;
    }else{
      this.fechaRellamadoDisabled = true;
      
    }
  
  }
    
  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.solicitud_tarjeta.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
    }


  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
        
       });


    });

  }  

  setCalendarLocale(){

    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mir", "Juv", "Vir", "Sab"],
      dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
      monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
      monthNamesShort: [ "Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
  };
  }
  

  public validationsForm(){
    this.validations = new Array<any>();

    
    if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.accion) == null){
      this.aplicanteCurrent.validacion.accion = "";
      this.validations.push({"validacion.accion" : "este campos es requerido."})
    }  

    if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.motivo) == null){
      this.aplicanteCurrent.validacion.motivo = "";
      this.validations.push({"validacion.motivo" : "este campos es requerido."})
    } 

       
    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_primero)){
      this.validations.push({"datos_personales.nombre_primero" : "este campos es requerido."});
      }

      if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.apellido_primero)){
            this.validations.push({"datos_personales.apellido_primero" : "este campos es requerido."});
      }	 

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_completo ))
       this.validations.push({"datos_personales.nombre_completo" : "este campos es requerido."})

    if(this.nacionalidadesList .data.find(v=>v.key==this.aplicanteCurrent.datos_personales.nacionalidad) == null){
        this.aplicanteCurrent.datos_personales.nacionalidad = "";
        this.validations.push({"datos_personales.nacionalidad" : "este campos es requerido."})
    }  

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento))
       this.validations.push({"datos_personales.fecha_nacimiento" : "este campos es requerido."})

    if(!this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento)){
      
        let b = moment(this.aplicanteCurrent.datos_personales.fecha_nacimiento, "DD/MM/YYYY");
        
        if(moment().diff(b, 'years') < 18)       
          this.validations.push({"datos_personales.fecha_nacimiento" : "no se permite menor a 18 años"})
    }

    if(this.provinciasList .data.find(v=>v.key==this.aplicanteCurrent.datos_personales.provincia) == null){
        this.aplicanteCurrent.datos_personales.provincia = "";
        this.validations.push({"datos_personales.provincia" : "este campos es requerido."})
    }  

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.localidad))
       this.validations.push({"datos_personales.localidad" : "este campos es requerido."})

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.codigo_postal))
       this.validations.push({"datos_personales.codigo_postal" : "este campos es requerido."})

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle))
       this.validations.push({"datos_personales.calle" : "este campos es requerido."})

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle_nro)){
     this.validations.push({"datos_personales.calle_nro" : "este campos es requerido."})
    }

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
       this.validations.push({"datos_contacto.celular" : "este campos es requerido."})
    
    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
       this.validations.push({"datos_contacto.email_personal" : "este campos es requerido."})

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
      let rxEmail = new RegExp(Environment.RegExEmail);
      if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
        this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
      }
    }       
    
    if(this.profesionesList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.profesion) == null){
      this.aplicanteCurrent.datos_laborales.profesion = "";
      this.validations.push({"datos_laborales.profesion" : "este campos es requerido."})
    }  


    if(this.trabajosTipolList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.tipo_trabajador) == null){
      this.aplicanteCurrent.datos_laborales.tipo_trabajador = "";
      this.validations.push({"datos_laborales.tipo_trabajador" : "este campos es requerido."})
    }  

    if(!this.fechaRellamadoDisabled){
      
      if(this.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_rellamado))
         this.validations.push({"detalles.fecha_rellamado" : "este campos es requerido."});

      if(!this.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_rellamado)){
        
          let b = moment(this.aplicanteCurrent.detalles.fecha_rellamado, "DD/MM/YYYY");
          
          var duration = moment().diff(b, 'days') 

          if(duration> 0)       
            this.validations.push({"detalles.fecha_rellamado" : "Fecha de Rellamado debe ser igual o posterior al día de hoy."})
      }

      if(this.isNullOrEmpty(this.aplicanteCurrent.detalles.hora_rellamado))
          this.validations.push({"detalles.hora_rellamado" : "campo hora es requerido."});


    }

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.delivery_code)){
      this.validations.push({"detalles.delivery_code" : "este campos es requerido."});
    }

    return this.validations.length == 0;
  }

  public validationsMessage(name):string{
    if(!this.validationsShow){
      return null;
    }
      
    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  public validationsAdcionalForm(){
    this.validationsAdicional = new Array<any>();


    if(this.tiposDocumentosList.data.find(v=>v.key==this.adicionalCurrent.tipo_documento) == null){
      this.adicionalCurrent.tipo_documento = "";
      this.validationsAdicional.push({"adicionalCurrent.tipo_documento" : "este campos es requerido."})
    }  

    if(this.isNullOrEmpty(this.adicionalCurrent.numero_documento))
             this.validationsAdicional.push({"adicionalCurrent.numero_documento" : "este campos es requerido."});

    
    if(this.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento))
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "este campos es requerido."});


    if(!this.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento)){

      let b = moment(this.adicionalCurrent.fecha_nacimiento, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 14)       
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "no se permite menor a 14 años"})
    }         
    
    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_primero))
       this.validations.push({"datos_personales.nombre_primero" : "este campos es requerido."})

    if(this.isNullOrEmpty(this.aplicanteCurrent.datos_personales.apellido_primero))
       this.validations.push({"datos_personales.apellido_primero" : "este campos es requerido."})       

    if(this.isNullOrEmpty(this.adicionalCurrent.nombre_completo))
        this.validationsAdicional.push({"adicionalCurrent.nombre_completo" : "este campos es requerido."});


    if(this.isNullOrEmpty(this.adicionalCurrent.nombre_primero))
        this.validationsAdicional.push({"adicionalCurrent.nombre_primero" : "este campos es requerido."});


    if(this.isNullOrEmpty(this.adicionalCurrent.apellido_primero))
        this.validationsAdicional.push({"adicionalCurrent.apellido_primero" : "este campos es requerido."});



    if(this.vinculosList.data.find(v=>v.key==this.adicionalCurrent.vinculo) == null){
      this.adicionalCurrent.vinculo = "";
      this.validationsAdicional.push({"adicionalCurrent.vinculo" : "este campos es requerido."})
    }  


    if(this.generosList.data.find(v=>v.key==this.adicionalCurrent.genero) == null){
      this.adicionalCurrent.genero = "";
      this.validationsAdicional.push({"adicionalCurrent.genero" : "este campos es requerido."})
    }       

    return this.validationsAdicional.length == 0;
  }

  public validationsAdcionalMessage(name):string{
    if(!this.validationsAdicionalShow)
      return null;

    this.validationsAdcionalForm();

    let validation = this.validationsAdicional.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  direccionBinding(){    
  
    let direccion = (this.aplicanteCurrent.datos_personales.calle || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_nro || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_piso || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_dpto || "");    

    this.aplicanteCurrent.datos_personales.direccion = direccion.substring(0,25);
  }
    

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.docrecoveryData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "CUIL": item.cuit,
        "DNI":item.dni,
        "PCN":item.pcn,
        "FECHA":item.fecha,
        "ULTIMA MODIF":item.fecha_modificacion,
        "PRODUCTO":item.producto,
        "ESTADO":item.estado,
              
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }  

  
  exportReporteDO(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    let docrecoveryDO = this.docrecoveryData.filter(f => f.estado == "A ESPERA DE DOCUMENTACIÓN");
    
    docrecoveryDO.forEach(item => {    
   
      reportDataExport.push({
        "REFERENCIA":item.pcn,
        "NOMBRE":item.nombre,
        "APELLIDO":item.apellido,
        "PRODUCTO":item.producto,
        "CAMPAÑA":item.promotor, 
        "NACIONALIDAD":item.nacionalidad,
        "CÓDIGO DE ÁREA":item.codigo_area,
        "TELÉFONO":item.telefono,
        "EMAIL":item.email,
        "DNI":item.dni,
        "CUIL": item.cuit,
        "FECHA DE NACIMIENTO": item.fechaNacimiento,
        "PROFESIÓN": item.profesion,
        "CALLE/NUMERO/PISO/DEPARTAMENTO/LOCALIDAD/CP/PROVINCIA": item.direccion              
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, "REPORTE DO");

  }
  
  showButtonReporteDO():boolean{
    let roles = [Roles.AMX_ADMIN,
                Roles.AMX_AG_SUPER,
                Roles.AMX_RD_SUPER];

    return this.sessionStorageService.hasRolesUser(roles);
  }


  public isNullOrEmpty(value:any):boolean{
  
      return value==null || value == "";
  };

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}

public onKey(e){
  if(e.code == "Enter"){
    this.loadReportResults();
  }
}

onCont(operador){

  if(operador == "add"){

    this.aplicanteCurrent.datos_personales.cantRellamadosRec = this.aplicanteCurrent.datos_personales.cantRellamadosRec + 1;
  } else {
    if(this.aplicanteCurrent.datos_personales.cantRellamadosRec > 0){
      this.aplicanteCurrent.datos_personales.cantRellamadosRec = this.aplicanteCurrent.datos_personales.cantRellamadosRec - 1;
    }

  }
  return this.aplicanteCurrent.datos_personales.cantRellamadosRec;
}
}


